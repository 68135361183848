<template>
	<div class="row">
		<div class="col-md-6">
			<div class="row pb-4 align-items-center">
				<div class="col-md-4 text-right">
					<div class="h2 font-weight-bolder text-dark mb-0">RF Equipment</div>
				</div>
				<div class="col-md-8">
					<button class="btn btn-xs btn-secondary" @click="modemstatus(device)">
						<span class="svg-icon svg-icon-sm">
							<inline-svg src="/media/svg/icons/Devices/Diagnostics.svg" />
						</span>
						View Modem Status</button
					>&nbsp;
					<button v-if="showoptionfile" class="btn btn-xs btn-secondary" @click="downloadOptionFile(device.DeviceId)">
						<span class="svg-icon svg-icon-sm">
							<inline-svg src="/media/svg/icons/Files/Download.svg" />
						</span>
						Download Option File
						<b-spinner small v-if="downloadingOF" />
						<span v-if="changesPending" class="svg-icon svg-icon-sm svg-icon-warning" v-b-tooltip.hover.bottom="'Changes Pending'">
							<inline-svg src="/media/svg/icons/Code/Warning-1-circle.svg" />
						</span>

						<div v-if="dloferror" class="btn btn-icon btn-clean btn-sm btn-light-danger" v-b-tooltip.hover.bottom="'Error downloading option file'">
							<span class="svg-icon svg-icon-lg">
								<inline-svg src="/media/svg/icons/Code/Error-circle.svg" />
							</span>
						</div>
					</button>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<b-spinner v-if="!general.rfloaded" />
					<div v-if="general.rfloaded">
						<div class="card card-custom gutter-b">
							<div class="card-header">
								<div class="card-title">
									<h3 class="card-label">BUC</h3>
								</div>
							</div>

							<div class="card-body">
								<h4>{{ general.BUC.ManufacturerPartNum }}</h4>
								Type: {{ BUCTypes[general.BUC.BUCTypeId] }} &nbsp; Gain: {{ general.BUC.Gain }}dB &nbsp; Power: {{ general.BUC.Power }}dBm<br />
								Start Freq: {{ general.BUC.StartFreq }}MHz - Stop Freq: {{ general.BUC.StopFreq }}MHz<br />
								Frequency Translation: {{ general.BUC.FreqTran }}MHz<br />
								DC: {{ general.BUC.ODUTxDCPower ? 'Yes' : 'No' }} &nbsp; 10MHz: {{ general.BUC.ODUTx10MgHz ? 'Yes' : 'No' }} &nbsp; Spectral
								Inversion:
								{{ general.BUC.SpecInv ? 'Yes' : 'No' }}
							</div>
						</div>
						<div class="card card-custom gutter-b">
							<div class="card-header">
								<div class="card-title">
									<h3 class="card-label">LNB</h3>
								</div>
							</div>

							<div class="card-body">
								<h4>{{ general.LNB.ManufacturerPartNum }}</h4>
								Noise Figure: {{ general.LNB.NoiseFigure }}dB &nbsp; Gain: {{ general.LNB.Gain }}dB &nbsp; Stability: {{ general.LNB.Stability }} +/-
								MHz<br />
								Start Freq: {{ general.LNB.StartFreq }}MHz - Stop Freq: {{ general.LNB.StopFreq }}MHz<br />
								Frequency Translation: {{ general.LNB.FreqTran }}MHz<br />
								DC: {{ general.LNB.ODUTxDCPower ? 'Yes' : 'No' }} &nbsp; 10MHz: {{ general.LNB.ODUTx10MgHz ? 'Yes' : 'No' }} &nbsp; Spectral
								Inversion:
								{{ general.LNB.SpecInv ? 'Yes' : 'No' }}
							</div>
						</div>
						<div class="card card-custom gutter-b">
							<div class="card-header">
								<div class="card-title">
									<h3 class="card-label">Antenna</h3>
								</div>
							</div>

							<div class="card-body">
								<h4>{{ general.Reflector.ManufacturerPartNum }}</h4>
								Size: {{ general.Reflector.Size }}m<br />
								Offset Angle: {{ general.Reflector.OffsetAngle }}&deg; &nbsp; Approx. Cable Length: {{ general.RemoteAntenna.CableLength }}<br />
								Controllable: {{ general.Reflector.IsControllable ? 'Yes' : 'No' }} &nbsp; GPS Source:
								{{ general.Reflector.IsGPSSource ? 'Yes' : 'No' }}
								<div v-if="general.RemoteAntenna.AntennaAddress != '0.0.0.0'">
									IP: {{ general.RemoteAntenna.AntennaAddress }}:{{ general.RemoteAntenna.AntennaPort }} &nbsp; GPS Interval:
									{{ general.RemoteAntenna.GPSInterval }}s<br />
									Hunt Frequency: {{ general.RemoteAntenna.HuntFrequency }}MHz
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="row">
				<div class="col-md-12">
					<div class="card card-custom card-stretch gutter-b">
						<div class="card-header">
							<div class="card-title">
								<h3 class="card-label">Realtime Summary</h3>
							</div>
						</div>

						<div class="card-body pt-2">
							<div v-if="generalstats.showGeneralRealtime">
								<h4 v-b-tooltip.hover.left="'Timestamp ' + generalstats.remoteTimestamp" tooltip-placement="left">
									Downstream C/N: {{ generalstats.downsnr }} dB
									<span class="svg-icon svg-icon-sm svg-icon-success" v-if="generalstats.snrHigher">
										<inline-svg src="/media/svg/icons/Navigation/Angle-up.svg" />
									</span>

									<span class="svg-icon svg-icon-sm svg-icon-danger" v-if="generalstats.snrHigher < 0">
										<inline-svg src="/media/svg/icons/Navigation/Angle-down.svg" />
									</span>
								</h4>
								<h4 v-b-tooltip.hover.left="'Timestamp ' + generalstats.ucpTimestamp">
									Upstream C/N: {{ generalstats.upsnr }} dB
									<span class="svg-icon svg-icon-sm svg-icon-success" v-if="generalstats.upSnrHigher">
										<inline-svg src="/media/svg/icons/Navigation/Angle-up.svg" />
									</span>

									<span class="svg-icon svg-icon-sm svg-icon-danger" v-if="generalstats.upSnrHigher < 0">
										<inline-svg src="/media/svg/icons/Navigation/Angle-down.svg" />
									</span>
								</h4>
								<h4 v-b-tooltip.hover.left="'Timestamp ' + generalstats.remoteTimestamp" tooltip-placement="left">
									Tx Power: {{ generalstats.txPower }} dBm
									<span class="svg-icon svg-icon-sm svg-icon-success" v-if="generalstats.powerHigher">
										<inline-svg src="/media/svg/icons/Navigation/Angle-up.svg" />
									</span>

									<span class="svg-icon svg-icon-sm svg-icon-danger" v-if="generalstats.powerHigher < 0">
										<inline-svg src="/media/svg/icons/Navigation/Angle-down.svg" />
									</span>
								</h4>
								<h4 v-b-tooltip.hover.left="'Timestamp ' + generalstats.remoteTimestamp">
									Temperature:
									{{ generalstats.tempC }} &deg;C
									<span class="svg-icon svg-icon-sm svg-icon-success" v-if="generalstats.tempHigher">
										<inline-svg src="/media/svg/icons/Navigation/Angle-up.svg" />
									</span>

									<span class="svg-icon svg-icon-sm svg-icon-danger" v-if="generalstats.tempHigher < 0">
										<inline-svg src="/media/svg/icons/Navigation/Angle-down.svg" />
									</span>
								</h4>
							</div>
							<div v-if="!generalstats.showGeneralRealtime"><b style="position: relative; left: 40px">N/A</b><br /><br /><br /><br /><br /></div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="card card-custom card-stretch gutter-b">
						<div class="card-header">
							<div class="card-title">
								<h3 class="card-label">Rate Shaping<small>(kbps)</small></h3>
							</div>
						</div>

						<div class="card-body pt-2">
							<div class="row" v-if="general.modem">
								<div class="col-6">
									<h4>Downstream</h4>
									<div class="d-flex">
										<h5 class="mr-2">MIR:</h5>
										<h5 class="mr-2"><input type="checkbox" disabled v-model="general.modem.down.useMIR" /></h5>
										<h5 class="mr-2">Enabled: <input style="width: 40%" type="text" v-model="general.modem.down.MIR" readonly /></h5>
									</div>
									<div class="d-flex">
										<h5 class="mr-2">CIR:</h5>
										<h5 class="mr-2"><input type="checkbox" disabled v-model="general.modem.down.useCIR" /></h5>
										<h5 class="mr-2">Enabled: <input style="width: 40%" type="text" v-model="general.modem.down.CIR" readonly /></h5>
									</div>
								</div>
								<div class="col-6">
									<h4>Upstream</h4>
									<div class="d-flex">
										<h5 class="mr-2">MIR:</h5>
										<h5 class="mr-2"><input type="checkbox" disabled v-model="general.modem.up.useMIR" /></h5>
										<h5 class="mr-2">Enabled: <input style="width: 40%" type="text" v-model="general.modem.up.MIR" readonly /></h5>
									</div>
									<div class="d-flex">
										<h5 class="mr-2">CIR:</h5>
										<h5 class="mr-2"><input type="checkbox" disabled v-model="general.modem.up.useCIR" /></h5>
										<h5 class="mr-2">Enabled: <input style="width: 40%" type="text" v-model="general.modem.up.CIR" readonly /></h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal id="statusmodal">
			<template #modal-title> {{ device.Name }} Modem Status (From Telnet) </template>
			<b-spinner v-if="modalLoading" />
			<div v-if="!modalLoading" class="pre-line">
				{{ modemStatusData }}
			</div>
			<template #modal-footer="{ ok }">
				<b-button size="sm" variant="success" @click="ok()"> OK </b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

const date = new Date();
const offset = date.getTimezoneOffset() * 60000;

const BUCTypes = [
	'2W Ku-band',
	'4W Ku-band',
	'4W C-band',
	'8W Hi-Power Ku-band',
	'10W Hi-Power C-band',
	'20W Hi-Power C-band',
	'5W C-band',
	'2W C-band',
	'1W Ku-Band',
	'16W Hi-Power Ku-band',
	'7W Ku-band',
	'5W Ku-band',
	'3W Ku-band',
	'3W Ku-band ext',
	'1.5W Ku-band ext',
	'4W Ku-band ext',
];

export default {
	props: ['general', 'generalstats', 'device'],
	name: 'RealtimeGeneral',
	data() {
		return {
			BUCTypes: [],
			downloadingOF: false,
			dloferror: false,
			showoptionfile: false,
			changesPending: false,
			modalLoading: false,
			modemStatusData: '',
		};
	},
	computed: {
		...mapGetters(['currentUser']),
	},
	methods: {
		load() {
			this.$http.get(`idirect/configstatus/${this.device.DeviceId}`).then(resp => {
				this.changesPending = resp.data.data;
			});
		},
		downloadOptionFile(id) {
			this.downloadingOF = true;
			this.$http
				.get(`idirect/optionfile/${id}`)
				.then(resp => {
					let blob = new Blob([resp.data], { type: 'text/plain;charset=utf-8;' });
					let url = URL.createObjectURL(blob);
					const link = document.createElement('a');
					link.setAttribute('href', url);
					link.setAttribute('download', this.device.Name + '.opt');					
					link.click();
					this.downloadingOF = false;
					this.dloferror = false;
				})
				.catch(e => {
					console.log(e);
					this.dloferror = true;
					this.downloadingOF = false;
				});
		},
		modemstatus(d) {
			this.$bvModal.show('statusmodal');
			this.modalLoading = true;

			this.$http.get(`idirect/modemstatus/${d.DeviceId}`).then(resp => {
				this.modemStatusData = resp.data.data;
				this.modalLoading = false;
			});
		},
	},
	mounted() {
		this.BUCTypes = BUCTypes;
		if (['noc', 'admin'].includes(this.currentUser.role) || this.currentUser.showoptionfile) {
			this.showoptionfile = true;
		}
	},
	created() {
		if (this.device.deviceId) {
			this.load();
		}
	},
	watch: {
		device() {
			this.load();
		},
	},
};
</script>

<style>
.pre-line {
	white-space: pre-line;
}
</style>
